<template>
  <div class="view-live container-fluid py-4">
    <router-link
      :to="{ name: 'home' }"
      class="text-light mr-2 text-decoration-none"
    >
      <i class="icon-arrow-left"></i>
      Voltar
    </router-link>

    <div class="row">
      <div class="col-lg-8 py-4">
        <div id="ytVideo" class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            :src="videoSrc"
            allowfullscreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>

        <div class="d-flex align-items-center justify-content-start pt-4">
          <span class="lesson-number mr-3 bg-dark">Ao vivo</span>
          <div>
            <h3 class="font-weight-normal mb-0">{{ live.name }}</h3>
            <small>
              {{ live.teachers.map(t => t.name).join(", ") }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import get from "lodash/get";
import { youtubeParser } from "@/functions/helpers";

export default {
  computed: {
    ...mapState("lives", ["live"]),
    videoSrc() {
      // Insira aqui a URL do vídeo que deseja utilizar
      return "https://www.youtube.com/embed/kc1CIKDNdgs";
    }
  }
};
</script>

<style lang="scss">
.view-live {
  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 768px) {
    small {
      font-size: 12px !important;
    }
  }

  .btn {
    line-height: 34px;
  }

  button {
    i {
      font-size: 22px;
    }
  }

  .lesson-number {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px 24px;
    white-space: nowrap;
  }

  .embed-responsive.chat {
    padding-top: 116%;
  }
}
</style>
